.claim-checklist {
  padding: 4rem 0;
  background-color: $oh-white;

  .claim-checklist-inner {
    @include pulsar-container($oh-container-width-small);

    ul {
      margin-bottom: 2rem;

      li {
        position: relative;
        list-style: none;
        margin-bottom: 0.75rem;

        &:before {
          position: absolute;
          @include fa-pseudo-regular('\f058');
          top: 0.3em;
          left: -2em;
          color: $oh-green-medium;
        }
      }
    }
  }
}

.claims-questions {
  padding: 4rem 0;

  .claims-questions-inner {
    @include pulsar-container($oh-container-width-small);
  }

  .questions-contact {
    margin-bottom: 3rem;
    display: flex;
    gap: 1rem;
    @include font-size(18px);

    p {
      margin-bottom: 0;
    }

    .phone-icon {
      @include font-size(36px);
      color: $oh-green-dark;
      border: 4px solid $oh-green-dark;
      border-radius: 1000px;
      width: 72px;
      height: 72px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
    }

    h2 {
      @extend %oh-h3;
    }
  }
}
