.homepage-intro {
  padding: 2rem 0;

  .homepage-intro-inner {
    @include pulsar-container($oh-container-width-large);
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  h2 {
    font-family: 'Roboto', sans-serif;
    color: $primary;
    font-weight: bold;
    @include font-size(25px);
    text-transform: uppercase;
  }

  h1 {
    font-family: 'Anton', sans-serif;
    color: $primary;
    @include font-size(56px);
    text-transform: uppercase;
    margin-bottom: 0.75rem;
  }

  p {
    color: $primary;
    @include font-size(18px);
    max-width: 540px;
  }
}

.header-with-lines {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  margin-bottom: 0.5rem;
  align-items: center;
  gap: 0.5rem;

  h2 {
    margin: 0;
    line-height: 1em;
  }
}

.homepage-vault-breaker {
  background-image: url('/assets/frontend/images/homepage-vault-breaker-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2.5rem 0;
  border: 4px solid $oh-green-medium;
  border-left: none;
  border-right: none;

  .homepage-vault-breaker-inner {
    @include pulsar-container($oh-container-width-large);
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .vault-breaker-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    img {
      height: 125px;
    }
  }

  .vault-breaker-counter {
    font-family: 'Anton', sans-serif;
    color: white;
    @include font-size(72px);
    letter-spacing: 0.095em;
    line-height: 1em;
    margin-bottom: 1rem;
  }

  .vault-breaker-copy {
    max-width: 613px;
    color: white;

    .smaller-disclaimer {
      @include font-size(13px);
    }
  }

  .vault-breaker-more {
    a.btn {
      @extend .btn-arrow;
      padding-right: 4rem;
      padding-left: 2.25rem;

      &:after {
        right: 2.25rem;
      }
    }
  }
}

.claim-your-prize {
  display: grid;

  .claim-badge-wrap {
    margin-top: 2rem;

    grid-column: 1 / 2;
    grid-row: 1 / 3;

    display: flex;
    justify-content: center;

    filter: drop-shadow(0 2px 10px #051705);
  }

  .claim-badge {
    @include oh-bg-primary();

    width: 100%;
    max-width: 555px;
    clip-path: polygon(0 0, 100% 0%, 100% 70%, 50% 100%, 0 70%);
    text-align: center;
    padding: 1.25rem 1rem 0.25rem;
    margin-bottom: 1.5rem;

    .badge-copy {
      display: inline-block;
      margin: auto;
      border: 1px solid $oh-green-bright;
      border-left: 0;
      border-right: 0;
      margin-bottom: 0.5rem;
      padding: 0.5rem 0;

      > *:first-child {
        margin-top: 0;
      }

      > *:last-child {
        margin-bottom: 0;
      }

      h2 {
        font-family: 'Anton', sans-serif;
        font-weight: normal;
        margin-bottom: 0;
      }

      p {
        @include font-size(18px);
        font-weight: normal;
      }
    }

    .badge-arrow {
      font-size: 21px;
      color: $oh-green-bright;
    }
  }

  .claim-bg {
    position: relative;
    grid-column: 1 / 2;
    grid-row: 2 / 4;
    z-index: -1;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .claim-cards {
    grid-column: 1 / 2;
    grid-row: 3 / 4;

    margin-bottom: 2.75rem;

    .star-with-lines {
      margin-bottom: 0.75rem;
      width: 100%;

      &.left::before,
      &.right::after {
        content: '';
        width: clamp(30px, 40%, 100px);
        margin: auto;
      }

      &.left::before {
        margin-right: 5px;
      }

      &.right::after {
        margin-left: 5px;
      }
    }
  }

  .claim-cards-tiles {
    @include pulsar-container($oh-container-width-large);
    @include oh-tiles-grid(3);

    .claim-card {
      padding: 1.25rem;
      background-color: $oh-white;

      display: flex;
      flex-direction: column;
    }

    .claim-card--details {
      h3 {
        @include font-size(25px);
        text-align: center;
        margin-bottom: 0.25rem;
      }

      .exchange-tickets {
        font-weight: bold;

        p {
          margin-bottom: 0.5rem;
        }
      }
    }

    .claim-card--submit {
      margin-top: auto;

      p {
        margin: 0;
      }

      .btn {
        width: 100%;
      }
    }
  }
}

.star-with-lines {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: auto;

  i {
    font-size: 10px;
    color: $oh-green-medium;
  }

  &.left::before,
  &.right::after {
    content: '';
    display: block;
    width: clamp(30px, 9vw, 100px);
    height: 1px;
    background-color: $oh-green-medium;
  }

  &.left::before {
    margin-right: 5px;
  }

  &.right::after {
    margin-left: 5px;
  }
}

.pre-footer-banner {
  @include oh-bg-primary();

  padding: 1.75rem 0;

  .pre-footer-banner-inner {
    @include pulsar-container($oh-container-width-large);

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    p {
      margin: 0;
      @include font-size(25px);
      font-weight: normal;
    }
  }
}
