img {
  display: block;
  @include img-fluid;
}

.page-content {
  &.page-content__row {
    display: grid;

    &.page-content__row--one_column {
      grid-template-columns: 1fr;
    }

    &.page-content__row--two_column {
      grid-template-columns: 1fr 1fr;
    }

    &.page-content__row--three_column {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.page-content__row--sidebar_left {
      grid-template-columns: 1fr 3fr;
    }

    &.page-content__row--sidebar_right {
      grid-template-columns: 3fr 1fr;
    }
  }
}

// creating custom placeholders that act the same as h1 and .h1
// but avoid problems with extending classes

%oh-h1 {
  @extend %heading;
  @include font-size($h1-font-size);
}

%oh-h2 {
  @extend %heading;
  @include font-size($h2-font-size);
}

%oh-h3 {
  @extend %heading;
  @include font-size($h3-font-size);
}

%oh-h4 {
  @extend %heading;
  @include font-size($h4-font-size);
}

%oh-h5 {
  @extend %heading;
  @include font-size($h5-font-size);
}

%oh-h6 {
  @extend %heading;
  @include font-size($h6-font-size);
}

// custom heading styles that apply to all headings
%oh-heading {
  text-transform: none;
}

// custom heading styles for specific headings

h1,
.h1,
%oh-h1 {
  @extend %oh-heading;
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  line-height: 1.104em;
}

h2,
.h2,
%oh-h2 {
  @extend %oh-heading;
  font-family: 'Anton', sans-serif;
  font-weight: normal;
  color: $primary;
}

h3,
.h3,
%oh-h3 {
  @extend %oh-heading;
  letter-spacing: -0.01em;
}

h4,
.h4,
%oh-h4 {
  @extend %oh-heading;
}

h5,
.h5,
%oh-h5 {
  @extend %oh-heading;
}

h6,
.h6,
%oh-h6 {
  @extend %oh-heading;
}

a.btn {
  letter-spacing: 0.016em;

  &.btn-primary {
    &:hover {
      background-color: $oh-green-medium;
      border-color: $oh-green-medium;
      color: $black;
    }

    &:active {
      background-color: $oh-green-bright;
      border-color: $oh-green-bright;
      color: $black;
    }
  }
}

@mixin oh-bg-primary {
  background-color: $primary;
  color: $white;

  h2 {
    color: $white;
  }

  a {
    color: white;

    &:hover {
      color: $oh-green-bright;
    }
  }

  .star-with-lines {
    i {
      color: $oh-green-bright;
    }

    &:before,
    &:after {
      background-color: $oh-green-bright;
    }
  }
}

.link-unstyled,
.link-unstyled:link {
  color: inherit;
  text-decoration: inherit;

  &:hover {
    color: $oh-green-bright;
    text-decoration: underline;
  }
}

.main-header {
  @include oh-bg-primary();
  padding-top: 1rem;
  padding-bottom: 1rem;

  .header-inner {
    @include pulsar-container($oh-container-width-large);

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }

  @include media-breakpoint-down(md) {
    .header-inner {
      position: relative;
      justify-content: center;
      padding: 0 4rem;
    }

    .header-logo {
      max-width: 179px;
    }

    .header-nav {
      position: absolute;
      right: 12px;
    }
  }
}

.header-nav {
  .offcanvas {
    --bs-offcanvas-bg: #{$primary};
    --bs-offcanvas-color: #{$white}; // if necessary
  }

  .navbar-nav {
    @extend .list-unstyled;
    margin: 0;

    .nav-link {
      @extend .link-unstyled;
      text-decoration: none !important;
      font-weight: bold;
      line-height: normal;
      white-space: nowrap;
      padding: 0.5em 0 !important;
      border-bottom: 1px solid transparent;
      margin: 0.25rem 0;

      &:hover,
      &.active {
        border-color: $oh-green-bright;
      }
    }
  }

  .navbar-toggler {
    padding: 0.25rem;
    border: none;
  }

  .dropdown-menu {
    a {
      color: $body-color;

      &:hover,
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .navbar-nav {
      gap: 1.5rem;

      .nav-item {
        &:last-of-type {
          .dropdown-menu {
            left: unset;
            right: 0;
          }
        }
      }
    }
  }
}

.main-footer {
  padding: 2.5rem 0;
  border-top: 2px solid $oh-green-medium;

  .main-footer-inner {
    @include pulsar-container($oh-container-width-large);
  }

  .footer-copy {
    max-width: 567px;
    display: flex;
    gap: 4rem;

    .footer-copy-left {
      position: relative;
      flex: 1 0 auto;

      @include font-size(18px);
    }

    .footer-copy-right {
      max-width: 342px;
    }
  }

  .footer-nav {
    ul {
      @extend .list-unstyled;

      display: flex;
      gap: 1rem;

      li {
        flex: 0 1 37%;
      }
    }
  }

  .footer-fine-details {
    @include font-size(13px);
  }

  @include media-breakpoint-up(md) {
    .main-footer-inner {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }

    .footer-copy {
      .footer-copy-left {
        &:after {
          content: '';
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: $gray-500;
          top: 0;
          right: -2rem;
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .footer-copy {
      gap: 2rem;

      .footer-copy-left {
        &:after {
          right: -1rem;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .main-footer-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    .footer-copy {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      gap: 1rem;
    }

    .footer-nav {
      ul {
        justify-content: center;
        gap: 2rem;

        li {
          flex-basis: auto;
        }
      }
    }
  }
}

.btn-pdf {
  position: relative;
  padding-right: 4rem;

  &:after {
    @include fa-pseudo-solid('\f1c1');
    @include font-size(24px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
  }
}

.btn-arrow {
  position: relative;
  padding-right: 3rem;

  &:after {
    @include fa-pseudo-solid('\f061');
    @include font-size(21px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.25rem;
  }
}

.btn-back {
  position: relative;
  padding-left: 4rem;

  &:before {
    @include fa-pseudo-solid('\f060');
    @include font-size(24px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem;
  }
}

.link-back {
  position: relative;
  padding-left: 2.25rem;
  font-weight: bold;
  text-decoration: none;

  &:before {
    @include fa-pseudo-solid('\f060');
    @include font-size(24px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
}

.blockquote {
  @extend %oh-h3;
  margin: 3rem auto;
  max-width: $oh-container-width-small;
}

blockquote {
  @extend .blockquote;
}

table {
  @extend .table;
}

.oh-fancybox-div {
  display: none;
}

.mb-3 {
  margin-bottom: $spacer;
}

.col-form-label {
  @extend %oh-h2;
}

form {
  .form-label.required {
    &:after {
      content: '\00a0\002A';
    }
  }
}

@import '~nice-select2/dist/css/nice-select2.css';

.nice-select {
  float: none;
  font-size: inherit;
  height: auto;
  @extend .form-select;

  &.open,
  &:active,
  &:focus {
    // copy/paste of .form-control &:focus styles from Bootstrap
    // because pseudo selectors cannot be extended
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      @include box-shadow($input-box-shadow, $input-focus-box-shadow);
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: $input-focus-box-shadow;
    }
  }

  &::after {
    display: none;
  }

  .nice-select-dropdown {
    right: 0;
  }

  .list {
    padding: 5px 10px;
  }
}

.alert-bar {
  @include oh-bg-primary();
  margin-bottom: 0;

  .alert-bar-content {
    > * {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .btn-close {
    filter: var(--bs-btn-close-white-filter);
  }
}

.marquee-container {
  > * {
    margin: 0;
  }
}
