// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
// $white:    #fff !default;
// $gray-100: #f8f9fa !default;
// $gray-200: #e9ecef !default;
// $gray-300: #dee2e6 !default;
// $gray-400: #ced4da !default;
// $gray-500: #adb5bd !default;
// $gray-600: #6c757d !default;
// $gray-700: #495057 !default;
// $gray-800: #343a40 !default;
// $gray-900: #212529 !default;
// $black:    #000 !default;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
// $blue:    #0d6efd !default;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #d63384 !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
// $green:   #198754 !default;
// $teal:    #20c997 !default;
// $cyan:    #0dcaf0 !default;
// scss-docs-end color-variables

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
// $min-contrast-ratio:   4.5 !default;

// Customize the light and dark text colors for use in our color contrast function.
// $color-contrast-dark:      $black !default;
// $color-contrast-light:     $white !default;

// scss-docs-start theme-color-variables
$primary: $oh-green-dark;
// $secondary:     $gray-600 !default;
// $success:       $green !default;
// $info:          $cyan !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;
// scss-docs-end theme-color-variables

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
// $spacer: 1rem !default;
// $spacers: (
//   0: 0,
//   1: $spacer * .25,
//   2: $spacer * .5,
//   3: $spacer,
//   4: $spacer * 1.5,
//   5: $spacer * 3,
// ) !default;
// scss-docs-end spacer-variables-maps

// Body
//
// Settings for the `<body>` element.

// $body-text-align:           null !default;
// $body-color:                $gray-900 !default;
// $body-bg:                   $white !default;

// $body-secondary-color:      rgba($body-color, .75) !default;
// $body-secondary-bg:         $gray-200 !default;

// $body-tertiary-color:       rgba($body-color, .5) !default;
// $body-tertiary-bg:          $gray-100 !default;

// $body-emphasis-color:       $black !default;

// Links
//
// Style anchor elements.

// $link-color:                              $primary !default;
// $link-decoration:                         underline !default;
// $link-shade-percentage:                   20% !default;
// $link-hover-color:                        shift-color($link-color, $link-shade-percentage) !default;
// $link-hover-decoration:                   null !default;

// $stretched-link-pseudo-element:           after !default;
// $stretched-link-z-index:                  1 !default;

// Icon links
// scss-docs-start icon-link-variables
// $icon-link-gap:               .375rem !default;
// $icon-link-underline-offset:  .25em !default;
// $icon-link-icon-size:         1em !default;
// $icon-link-icon-transition:   .2s ease-in-out transform !default;
// $icon-link-icon-transform:    translate3d(.25em, 0, 0) !default;
// scss-docs-end icon-link-variables

// Paragraphs
//
// Style p element.

// $paragraph-margin-bottom:   1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px,
//   xxl: 1400px
// ) !default;
// scss-docs-end grid-breakpoints

// @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
// @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px,
//   xxl: 1320px
// ) !default;
// scss-docs-end container-max-widths

// @include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

// $grid-columns:                12 !default;
// $grid-gutter-width:           1.5rem !default;
// $grid-row-columns:            6 !default;

// Container padding

// $container-padding-x: $grid-gutter-width !default;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
// $border-width:                1px !default;
// $border-widths: (
//   1: 1px,
//   2: 2px,
//   3: 3px,
//   4: 4px,
//   5: 5px
// ) !default;
// $border-style:                solid !default;
// $border-color:                $gray-300 !default;
// $border-color-translucent:    rgba($black, .175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius: 0;
// $border-radius-sm:            .25rem !default;
// $border-radius-lg:            .5rem !default;
// $border-radius-xl:            1rem !default;
// $border-radius-xxl:           2rem !default;
// $border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-start box-shadow-variables
// $box-shadow:                  0 .5rem 1rem rgba($black, .15) !default;
// $box-shadow-sm:               0 .125rem .25rem rgba($black, .075) !default;
// $box-shadow-lg:               0 1rem 3rem rgba($black, .175) !default;
// $box-shadow-inset:            inset 0 1px 2px rgba($black, .075) !default;
// scss-docs-end box-shadow-variables

// $component-active-color:      $white !default;
// $component-active-bg:         $primary !default;

// scss-docs-start focus-ring-variables
// $focus-ring-width:      .25rem !default;
// $focus-ring-opacity:    .25 !default;
// $focus-ring-color:      rgba($primary, $focus-ring-opacity) !default;
// $focus-ring-blur:       0 !default;
// $focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color !default;
// scss-docs-end focus-ring-variables

// scss-docs-start caret-variables
// $caret-width:                 .3em !default;
// $caret-vertical-align:        $caret-width * .85 !default;
// $caret-spacing:               $caret-width * .85 !default;
// scss-docs-end caret-variables

// $transition-base:             all .2s ease-in-out !default;
// $transition-fade:             opacity .15s linear !default;
// scss-docs-start collapse-transition
// $transition-collapse:         height .35s ease !default;
// $transition-collapse-width:   width .35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
// $aspect-ratios: (
//   "1x1": 100%,
//   "4x3": calc(3 / 4 * 100%),
//   "16x9": calc(9 / 16 * 100%),
//   "21x9": calc(9 / 21 * 100%)
// ) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
// $font-family-sans-serif:      system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
// $font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case
$font-family-base: 'Roboto', sans-serif;
// $font-family-code:            var(--#{$prefix}font-monospace) !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
// $font-size-root:              null !default;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
// $font-size-sm:                $font-size-base * .875 !default;
// $font-size-lg:                $font-size-base * 1.25 !default;

// $font-weight-lighter:         lighter !default;
// $font-weight-light:           300 !default;
// $font-weight-normal:          400 !default;
// $font-weight-medium:          500 !default;
// $font-weight-semibold:        600 !default;
// $font-weight-bold:            700 !default;
// $font-weight-bolder:          bolder !default;

// $font-weight-base:            $font-weight-normal !default;

$line-height-base: 1.75;
// $line-height-sm:              1.25 !default;
// $line-height-lg:              2 !default;

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.4375;
$h3-font-size: $font-size-base * 1.9375;
$h4-font-size: $font-size-base * 1.5625;
$h5-font-size: $font-size-base * 1.25;
// $h6-font-size:                $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start headings-variables
// $headings-margin-bottom:      $spacer * .5 !default;
$headings-font-family: $font-family-base;
// $headings-font-style:         null !default;
$headings-font-weight: bold;
$headings-line-height: normal;
// $headings-color:              inherit !default;
// scss-docs-end headings-variables

// scss-docs-start display-headings
// $display-font-sizes: (
//   1: 5rem,
//   2: 4.5rem,
//   3: 4rem,
//   4: 3.5rem,
//   5: 3rem,
//   6: 2.5rem
// ) !default;

// $display-font-family: null !default;
// $display-font-style:  null !default;
// $display-font-weight: 300 !default;
// $display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

// scss-docs-start type-variables
// $lead-font-size:              $font-size-base * 1.25 !default;
// $lead-font-weight:            300 !default;

// $small-font-size:             .875em !default;

// $sub-sup-font-size:           .75em !default;

// $initialism-font-size:        $small-font-size !default;

// $blockquote-margin-y:         $spacer !default;
// $blockquote-font-size:        $font-size-base * 1.25 !default;
// $blockquote-footer-color:     $gray-600 !default;
// $blockquote-footer-font-size: $small-font-size !default;

// $hr-margin-y:                 $spacer !default;
// $hr-color:                    inherit !default;

// $hr-border-color:             null !default; // Allows for inherited colors
// $hr-border-width:             var(--#{$prefix}border-width) !default;
// $hr-opacity:                  .25 !default;

// scss-docs-start vr-variables
// $vr-border-width:             var(--#{$prefix}border-width) !default;
// scss-docs-end vr-variables

// $legend-margin-bottom:        .5rem !default;
// $legend-font-size:            1.5rem !default;
// $legend-font-weight:          null !default;

// $dt-font-weight:              $font-weight-bold !default;

// $list-inline-padding:         .5rem !default;

// $mark-padding:                .1875em !default;
// $mark-color:                  $body-color !default;
// $mark-bg:                     $yellow-100 !default;
// scss-docs-end type-variables

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
// $table-cell-padding-y:        .5rem !default;
// $table-cell-padding-x:        .5rem !default;
// $table-cell-padding-y-sm:     .25rem !default;
// $table-cell-padding-x-sm:     .25rem !default;

// $table-cell-vertical-align:   top !default;

// $table-color:                 var(--#{$prefix}emphasis-color) !default;
// $table-bg:                    var(--#{$prefix}body-bg) !default;
// $table-accent-bg:             transparent !default;

// $table-th-font-weight:        null !default;

// $table-striped-color:         $table-color !default;
// $table-striped-bg-factor:     .05 !default;
// $table-striped-bg:            rgba(var(--#{$prefix}emphasis-color-rgb), $table-striped-bg-factor) !default;

// $table-active-color:          $table-color !default;
// $table-active-bg-factor:      .1 !default;
// $table-active-bg:             rgba(var(--#{$prefix}emphasis-color-rgb), $table-active-bg-factor) !default;

// $table-hover-color:           $table-color !default;
// $table-hover-bg-factor:       .075 !default;
// $table-hover-bg:              rgba(var(--#{$prefix}emphasis-color-rgb), $table-hover-bg-factor) !default;

// $table-border-factor:         .2 !default;
// $table-border-width:          var(--#{$prefix}border-width) !default;
// $table-border-color:          var(--#{$prefix}border-color) !default;

// $table-striped-order:         odd !default;
// $table-striped-columns-order: even !default;

// $table-group-separator-color: currentcolor !default;

// $table-caption-color:         var(--#{$prefix}secondary-color) !default;

// $table-bg-scale:              -80% !default;
// scss-docs-end table-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.575rem;
$input-btn-padding-x: 1.25rem;
// $input-btn-font-family:       null !default;
// $input-btn-font-size:         $font-size-base !default;
// $input-btn-line-height:       $line-height-base !default;

// $input-btn-focus-width:         $focus-ring-width !default;
// $input-btn-focus-color-opacity: $focus-ring-opacity !default;
// $input-btn-focus-color:         $focus-ring-color !default;
// $input-btn-focus-blur:          $focus-ring-blur !default;
// $input-btn-focus-box-shadow:    $focus-ring-box-shadow !default;

// $input-btn-padding-y-sm:      .25rem !default;
// $input-btn-padding-x-sm:      .5rem !default;
// $input-btn-font-size-sm:      $font-size-sm !default;

// $input-btn-padding-y-lg:      .5rem !default;
// $input-btn-padding-x-lg:      1rem !default;
// $input-btn-font-size-lg:      $font-size-lg !default;

// $input-btn-border-width:      var(--#{$prefix}border-width) !default;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
// $btn-color:                   var(--#{$prefix}body-color) !default;
// $btn-padding-y:               $input-btn-padding-y !default;
// $btn-padding-x:               $input-btn-padding-x !default;
// $btn-font-family:             $input-btn-font-family !default;
// $btn-font-size:               $input-btn-font-size !default;
// $btn-line-height:             $input-btn-line-height !default;
// $btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

// $btn-padding-y-sm:            $input-btn-padding-y-sm !default;
// $btn-padding-x-sm:            $input-btn-padding-x-sm !default;
// $btn-font-size-sm:            $input-btn-font-size-sm !default;

// $btn-padding-y-lg:            $input-btn-padding-y-lg !default;
// $btn-padding-x-lg:            $input-btn-padding-x-lg !default;
// $btn-font-size-lg:            $input-btn-font-size-lg !default;

// $btn-border-width:            $input-btn-border-width !default;

$btn-font-weight: bold;
// $btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
// $btn-focus-width:             $input-btn-focus-width !default;
// $btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
// $btn-disabled-opacity:        .65 !default;
// $btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

// $btn-link-color:              var(--#{$prefix}link-color) !default;
// $btn-link-hover-color:        var(--#{$prefix}link-hover-color) !default;
// $btn-link-disabled-color:     $gray-600 !default;
// $btn-link-focus-shadow-rgb:   to-rgb(mix(color-contrast($link-color), $link-color, 15%)) !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// $btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// $btn-hover-bg-shade-amount:       15% !default;
// $btn-hover-bg-tint-amount:        15% !default;
// $btn-hover-border-shade-amount:   20% !default;
// $btn-hover-border-tint-amount:    10% !default;
// $btn-active-bg-shade-amount:      20% !default;
// $btn-active-bg-tint-amount:       20% !default;
// $btn-active-border-shade-amount:  25% !default;
// $btn-active-border-tint-amount:   10% !default;
// scss-docs-end btn-variables

// Forms

// scss-docs-start form-text-variables
// $form-text-margin-top:                  .25rem !default;
// $form-text-font-size:                   $small-font-size !default;
// $form-text-font-style:                  null !default;
// $form-text-font-weight:                 null !default;
// $form-text-color:                       var(--#{$prefix}secondary-color) !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
// $form-label-margin-bottom:              .5rem !default;
// $form-label-font-size:                  null !default;
// $form-label-font-style:                 null !default;
// $form-label-font-weight:                null !default;
// $form-label-color:                      null !default;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
// $input-padding-y:                       $input-btn-padding-y !default;
// $input-padding-x:                       $input-btn-padding-x !default;
// $input-font-family:                     $input-btn-font-family !default;
// $input-font-size:                       $input-btn-font-size !default;
// $input-font-weight:                     $font-weight-base !default;
// $input-line-height:                     $input-btn-line-height !default;

// $input-padding-y-sm:                    $input-btn-padding-y-sm !default;
// $input-padding-x-sm:                    $input-btn-padding-x-sm !default;
// $input-font-size-sm:                    $input-btn-font-size-sm !default;

// $input-padding-y-lg:                    $input-btn-padding-y-lg !default;
// $input-padding-x-lg:                    $input-btn-padding-x-lg !default;
// $input-font-size-lg:                    $input-btn-font-size-lg !default;

// $input-bg:                              var(--#{$prefix}body-bg) !default;
// $input-disabled-color:                  null !default;
// $input-disabled-bg:                     var(--#{$prefix}secondary-bg) !default;
// $input-disabled-border-color:           null !default;

// $input-color:                           var(--#{$prefix}body-color) !default;
// $input-border-color:                    var(--#{$prefix}border-color) !default;
// $input-border-width:                    $input-btn-border-width !default;
// $input-box-shadow:                      var(--#{$prefix}box-shadow-inset) !default;

$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

// $input-focus-bg:                        $input-bg !default;
// $input-focus-border-color:              tint-color($component-active-bg, 50%) !default;
// $input-focus-color:                     $input-color !default;
// $input-focus-width:                     $input-btn-focus-width !default;
// $input-focus-box-shadow:                $input-btn-focus-box-shadow !default;

// $input-placeholder-color:               var(--#{$prefix}secondary-color) !default;
// $input-plaintext-color:                 var(--#{$prefix}body-color) !default;

// $input-height-border:                   calc(#{$input-border-width} * 2) !default; // stylelint-disable-line function-disallowed-list

// $input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) !default;
// $input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) !default;
// $input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y * .5) !default;

// $input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) !default;
// $input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
// $input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) !default;

// $input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// $form-color-width:                      3rem !default;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
// $form-check-input-width:                  1em !default;
// $form-check-min-height:                   $font-size-base * $line-height-base !default;
// $form-check-padding-start:                $form-check-input-width + .5em !default;
// $form-check-margin-bottom:                .125rem !default;
// $form-check-label-color:                  null !default;
// $form-check-label-cursor:                 null !default;
// $form-check-transition:                   null !default;

// $form-check-input-active-filter:          brightness(90%) !default;

// $form-check-input-bg:                     $input-bg !default;
// $form-check-input-border:                 var(--#{$prefix}border-width) solid var(--#{$prefix}border-color) !default;
// $form-check-input-border-radius:          .25em !default;
// $form-check-radio-border-radius:          50% !default;
// $form-check-input-focus-border:           $input-focus-border-color !default;
// $form-check-input-focus-box-shadow:       $focus-ring-box-shadow !default;

// $form-check-input-checked-color:          $component-active-color !default;
// $form-check-input-checked-bg-color:       $component-active-bg !default;
// $form-check-input-checked-border-color:   $form-check-input-checked-bg-color !default;
// $form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
// $form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

// $form-check-input-indeterminate-color:          $component-active-color !default;
// $form-check-input-indeterminate-bg-color:       $component-active-bg !default;
// $form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
// $form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;

// $form-check-input-disabled-opacity:        .5 !default;
// $form-check-label-disabled-opacity:        $form-check-input-disabled-opacity !default;
// $form-check-btn-check-disabled-opacity:    $btn-disabled-opacity !default;

// $form-check-inline-margin-end:    1rem !default;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
// $form-switch-color:               rgba($black, .25) !default;
// $form-switch-width:               2em !default;
// $form-switch-padding-start:       $form-switch-width + .5em !default;
// $form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
// $form-switch-border-radius:       $form-switch-width !default;
// $form-switch-transition:          background-position .15s ease-in-out !default;

// $form-switch-focus-color:         $input-focus-border-color !default;
// $form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") !default;

// $form-switch-checked-color:       $component-active-color !default;
// $form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
// $form-switch-checked-bg-position: right center !default;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
// $input-group-addon-padding-y:           $input-padding-y !default;
// $input-group-addon-padding-x:           $input-padding-x !default;
// $input-group-addon-font-weight:         $input-font-weight !default;
// $input-group-addon-color:               $input-color !default;
// $input-group-addon-bg:                  var(--#{$prefix}tertiary-bg) !default;
// $input-group-addon-border-color:        $input-border-color !default;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
// $form-select-padding-y:             $input-padding-y !default;
// $form-select-padding-x:             $input-padding-x !default;
// $form-select-font-family:           $input-font-family !default;
// $form-select-font-size:             $input-font-size !default;
// $form-select-indicator-padding:     $form-select-padding-x * 3 !default; // Extra padding for background-image
// $form-select-font-weight:           $input-font-weight !default;
// $form-select-line-height:           $input-line-height !default;
// $form-select-color:                 $input-color !default;
// $form-select-bg:                    $input-bg !default;
// $form-select-disabled-color:        null !default;
// $form-select-disabled-bg:           $input-disabled-bg !default;
// $form-select-disabled-border-color: $input-disabled-border-color !default;
// $form-select-bg-position:           right $form-select-padding-x center !default;
// $form-select-bg-size:               16px 12px !default; // In pixels because image dimensions
// $form-select-indicator-color:       $gray-800 !default;
// $form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>") !default;

// $form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 + $form-select-indicator-padding !default;
// $form-select-feedback-icon-position:    center right $form-select-indicator-padding !default;
// $form-select-feedback-icon-size:        $input-height-inner-half $input-height-inner-half !default;

// $form-select-border-width:        $input-border-width !default;
// $form-select-border-color:        $input-border-color !default;
// $form-select-border-radius:       $input-border-radius !default;
// $form-select-box-shadow:          var(--#{$prefix}box-shadow-inset) !default;

// $form-select-focus-border-color:  $input-focus-border-color !default;
// $form-select-focus-width:         $input-focus-width !default;
// $form-select-focus-box-shadow:    0 0 0 $form-select-focus-width $input-btn-focus-color !default;

// $form-select-padding-y-sm:        $input-padding-y-sm !default;
// $form-select-padding-x-sm:        $input-padding-x-sm !default;
// $form-select-font-size-sm:        $input-font-size-sm !default;
// $form-select-border-radius-sm:    $input-border-radius-sm !default;

// $form-select-padding-y-lg:        $input-padding-y-lg !default;
// $form-select-padding-x-lg:        $input-padding-x-lg !default;
// $form-select-font-size-lg:        $input-font-size-lg !default;
// $form-select-border-radius-lg:    $input-border-radius-lg !default;

// $form-select-transition:          $input-transition !default;
// scss-docs-end form-select-variables

// scss-docs-start form-range-variables
// $form-range-track-width:          100% !default;
// $form-range-track-height:         .5rem !default;
// $form-range-track-cursor:         pointer !default;
// $form-range-track-bg:             var(--#{$prefix}secondary-bg) !default;
// $form-range-track-border-radius:  1rem !default;
// $form-range-track-box-shadow:     var(--#{$prefix}box-shadow-inset) !default;

// $form-range-thumb-width:                   1rem !default;
// $form-range-thumb-height:                  $form-range-thumb-width !default;
// $form-range-thumb-bg:                      $component-active-bg !default;
// $form-range-thumb-border:                  0 !default;
// $form-range-thumb-border-radius:           1rem !default;
// $form-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) !default;
// $form-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow !default;
// $form-range-thumb-focus-box-shadow-width:  $input-focus-width !default; // For focus box shadow issue in Edge
// $form-range-thumb-active-bg:               tint-color($component-active-bg, 70%) !default;
// $form-range-thumb-disabled-bg:             var(--#{$prefix}secondary-color) !default;
// $form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
// scss-docs-end form-range-variables

// scss-docs-start form-file-variables
// $form-file-button-color:          $input-color !default;
// $form-file-button-bg:             var(--#{$prefix}tertiary-bg) !default;
// $form-file-button-hover-bg:       var(--#{$prefix}secondary-bg) !default;
// scss-docs-end form-file-variables

// scss-docs-start form-floating-variables
// $form-floating-height:                  add(3.5rem, $input-height-border) !default;
// $form-floating-line-height:             1.25 !default;
// $form-floating-padding-x:               $input-padding-x !default;
// $form-floating-padding-y:               1rem !default;
// $form-floating-input-padding-t:         1.625rem !default;
// $form-floating-input-padding-b:         .625rem !default;
// $form-floating-label-height:            1.5em !default;
// $form-floating-label-opacity:           .65 !default;
// $form-floating-label-transform:         scale(.85) translateY(-.5rem) translateX(.15rem) !default;
// $form-floating-label-disabled-color:    $gray-600 !default;
// $form-floating-transition:              opacity .1s ease-in-out, transform .1s ease-in-out !default;
// scss-docs-end form-floating-variables

// Form validation

// scss-docs-start form-feedback-variables
// $form-feedback-margin-top:          $form-text-margin-top !default;
// $form-feedback-font-size:           $form-text-font-size !default;
// $form-feedback-font-style:          $form-text-font-style !default;
// $form-feedback-valid-color:         $success !default;
// $form-feedback-invalid-color:       $danger !default;

// $form-feedback-icon-valid-color:    $form-feedback-valid-color !default;
// $form-feedback-icon-valid:          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
// $form-feedback-icon-invalid-color:  $form-feedback-invalid-color !default;
// $form-feedback-icon-invalid:        url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;
// scss-docs-end form-feedback-variables

// scss-docs-start form-validation-colors
// $form-valid-color:                  $form-feedback-valid-color !default;
// $form-valid-border-color:           $form-feedback-valid-color !default;
// $form-invalid-color:                $form-feedback-invalid-color !default;
// $form-invalid-border-color:         $form-feedback-invalid-color !default;
// scss-docs-end form-validation-colors

// Z-index master list

// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
// $zindex-dropdown:                   1000 !default;
// $zindex-sticky:                     1020 !default;
// $zindex-fixed:                      1030 !default;
// $zindex-offcanvas-backdrop:         1040 !default;
// $zindex-offcanvas:                  1045 !default;
// $zindex-modal-backdrop:             1050 !default;
// $zindex-modal:                      1055 !default;
// $zindex-popover:                    1070 !default;
// $zindex-tooltip:                    1080 !default;
// $zindex-toast:                      1090 !default;
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
// $nav-link-padding-y:                .5rem !default;
// $nav-link-padding-x:                1rem !default;
// $nav-link-font-size:                null !default;
// $nav-link-font-weight:              null !default;
// $nav-link-color:                    var(--#{$prefix}link-color) !default;
// $nav-link-hover-color:              var(--#{$prefix}link-hover-color) !default;
// $nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
// $nav-link-disabled-color:           var(--#{$prefix}secondary-color) !default;
// $nav-link-focus-box-shadow:         $focus-ring-box-shadow !default;

// $nav-tabs-border-color:             var(--#{$prefix}border-color) !default;
// $nav-tabs-border-width:             var(--#{$prefix}border-width) !default;
// $nav-tabs-border-radius:            var(--#{$prefix}border-radius) !default;
// $nav-tabs-link-hover-border-color:  var(--#{$prefix}secondary-bg) var(--#{$prefix}secondary-bg) $nav-tabs-border-color !default;
// $nav-tabs-link-active-color:        var(--#{$prefix}emphasis-color) !default;
// $nav-tabs-link-active-bg:           var(--#{$prefix}body-bg) !default;
// $nav-tabs-link-active-border-color: var(--#{$prefix}border-color) var(--#{$prefix}border-color) $nav-tabs-link-active-bg !default;

// $nav-pills-border-radius:           var(--#{$prefix}border-radius) !default;
// $nav-pills-link-active-color:       $component-active-color !default;
// $nav-pills-link-active-bg:          $component-active-bg !default;

// $nav-underline-gap:                 1rem !default;
// $nav-underline-border-width:        .125rem !default;
// $nav-underline-link-active-color:   var(--#{$prefix}emphasis-color) !default;
// scss-docs-end nav-variables

// Navbar

// scss-docs-start navbar-variables
// $navbar-padding-y:                  $spacer * .5 !default;
// $navbar-padding-x:                  null !default;

// $navbar-nav-link-padding-x:         .5rem !default;

// $navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
// $nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
// $navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
// $navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
// $navbar-brand-margin-end:           1rem !default;

// $navbar-toggler-padding-y:          .25rem !default;
// $navbar-toggler-padding-x:          .75rem !default;
// $navbar-toggler-font-size:          $font-size-lg !default;
// $navbar-toggler-border-radius:      $btn-border-radius !default;
// $navbar-toggler-focus-width:        $btn-focus-width !default;
// $navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

// $navbar-light-color:                rgba(var(--#{$prefix}emphasis-color-rgb), .65) !default;
// $navbar-light-hover-color:          rgba(var(--#{$prefix}emphasis-color-rgb), .8) !default;
// $navbar-light-active-color:         rgba(var(--#{$prefix}emphasis-color-rgb), 1) !default;
// $navbar-light-disabled-color:       rgba(var(--#{$prefix}emphasis-color-rgb), .3) !default;
// $navbar-light-icon-color:           rgba($body-color, .75) !default;
// $navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-light-toggler-border-color: rgba(var(--#{$prefix}emphasis-color-rgb), .15) !default;
// $navbar-light-brand-color:          $navbar-light-active-color !default;
// $navbar-light-brand-hover-color:    $navbar-light-active-color !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-dark-variables
// $navbar-dark-color:                 rgba($white, .55) !default;
// $navbar-dark-hover-color:           rgba($white, .75) !default;
// $navbar-dark-active-color:          $white !default;
// $navbar-dark-disabled-color:        rgba($white, .25) !default;
// $navbar-dark-icon-color:            $navbar-dark-color !default;
// $navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
// $navbar-dark-toggler-border-color:  rgba($white, .1) !default;
// $navbar-dark-brand-color:           $navbar-dark-active-color !default;
// $navbar-dark-brand-hover-color:     $navbar-dark-active-color !default;
// scss-docs-end navbar-dark-variables

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
// $dropdown-min-width:                10rem !default;
// $dropdown-padding-x:                0 !default;
// $dropdown-padding-y:                .5rem !default;
// $dropdown-spacer:                   .125rem !default;
// $dropdown-font-size:                $font-size-base !default;
// $dropdown-color:                    var(--#{$prefix}body-color) !default;
// $dropdown-bg:                       var(--#{$prefix}body-bg) !default;
// $dropdown-border-color:             var(--#{$prefix}border-color-translucent) !default;
// $dropdown-border-radius:            var(--#{$prefix}border-radius) !default;
// $dropdown-border-width:             var(--#{$prefix}border-width) !default;
// $dropdown-inner-border-radius:      calc(#{$dropdown-border-radius} - #{$dropdown-border-width}) !default; // stylelint-disable-line function-disallowed-list
// $dropdown-divider-bg:               $dropdown-border-color !default;
// $dropdown-divider-margin-y:         $spacer * .5 !default;
// $dropdown-box-shadow:               var(--#{$prefix}box-shadow) !default;

// $dropdown-link-color:               var(--#{$prefix}body-color) !default;
// $dropdown-link-hover-color:         $dropdown-link-color !default;
// $dropdown-link-hover-bg:            var(--#{$prefix}tertiary-bg) !default;

// $dropdown-link-active-color:        $component-active-color !default;
// $dropdown-link-active-bg:           $component-active-bg !default;

// $dropdown-link-disabled-color:      var(--#{$prefix}tertiary-color) !default;

// $dropdown-item-padding-y:           $spacer * .25 !default;
// $dropdown-item-padding-x:           $spacer !default;

// $dropdown-header-color:             $gray-600 !default;
// $dropdown-header-padding-x:         $dropdown-item-padding-x !default;
// $dropdown-header-padding-y:         $dropdown-padding-y !default;
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
// $dropdown-dark-color:               $gray-300 !default;
// $dropdown-dark-bg:                  $gray-800 !default;
// $dropdown-dark-border-color:        $dropdown-border-color !default;
// $dropdown-dark-divider-bg:          $dropdown-divider-bg !default;
// $dropdown-dark-box-shadow:          null !default;
// $dropdown-dark-link-color:          $dropdown-dark-color !default;
// $dropdown-dark-link-hover-color:    $white !default;
// $dropdown-dark-link-hover-bg:       rgba($white, .15) !default;
// $dropdown-dark-link-active-color:   $dropdown-link-active-color !default;
// $dropdown-dark-link-active-bg:      $dropdown-link-active-bg !default;
// $dropdown-dark-link-disabled-color: $gray-500 !default;
// $dropdown-dark-header-color:        $gray-500 !default;
// scss-docs-end dropdown-dark-variables

// Pagination

// scss-docs-start pagination-variables
// $pagination-padding-y:              .375rem !default;
// $pagination-padding-x:              .75rem !default;
// $pagination-padding-y-sm:           .25rem !default;
// $pagination-padding-x-sm:           .5rem !default;
// $pagination-padding-y-lg:           .75rem !default;
// $pagination-padding-x-lg:           1.5rem !default;

// $pagination-font-size:              $font-size-base !default;

// $pagination-color:                  var(--#{$prefix}link-color) !default;
// $pagination-bg:                     var(--#{$prefix}body-bg) !default;
// $pagination-border-radius:          var(--#{$prefix}border-radius) !default;
// $pagination-border-width:           var(--#{$prefix}border-width) !default;
// $pagination-margin-start:           calc(#{$pagination-border-width} * -1) !default; // stylelint-disable-line function-disallowed-list
// $pagination-border-color:           var(--#{$prefix}border-color) !default;

// $pagination-focus-color:            var(--#{$prefix}link-hover-color) !default;
// $pagination-focus-bg:               var(--#{$prefix}secondary-bg) !default;
// $pagination-focus-box-shadow:       $focus-ring-box-shadow !default;
// $pagination-focus-outline:          0 !default;

// $pagination-hover-color:            var(--#{$prefix}link-hover-color) !default;
// $pagination-hover-bg:               var(--#{$prefix}tertiary-bg) !default;
// $pagination-hover-border-color:     var(--#{$prefix}border-color) !default; // Todo in v6: remove this?

// $pagination-active-color:           $component-active-color !default;
// $pagination-active-bg:              $component-active-bg !default;
// $pagination-active-border-color:    $component-active-bg !default;

// $pagination-disabled-color:         var(--#{$prefix}secondary-color) !default;
// $pagination-disabled-bg:            var(--#{$prefix}secondary-bg) !default;
// $pagination-disabled-border-color:  var(--#{$prefix}border-color) !default;

// $pagination-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// $pagination-border-radius-sm:       var(--#{$prefix}border-radius-sm) !default;
// $pagination-border-radius-lg:       var(--#{$prefix}border-radius-lg) !default;
// scss-docs-end pagination-variables

// Cards

// scss-docs-start card-variables
// $card-spacer-y:                     $spacer !default;
// $card-spacer-x:                     $spacer !default;
// $card-title-spacer-y:               $spacer * .5 !default;
// $card-title-color:                  null !default;
// $card-subtitle-color:               null !default;
// $card-border-width:                 var(--#{$prefix}border-width) !default;
// $card-border-color:                 var(--#{$prefix}border-color-translucent) !default;
// $card-border-radius:                var(--#{$prefix}border-radius) !default;
// $card-box-shadow:                   null !default;
// $card-inner-border-radius:          subtract($card-border-radius, $card-border-width) !default;
// $card-cap-padding-y:                $card-spacer-y * .5 !default;
// $card-cap-padding-x:                $card-spacer-x !default;
// $card-cap-bg:                       rgba(var(--#{$prefix}body-color-rgb), .03) !default;
// $card-cap-color:                    null !default;
// $card-height:                       null !default;
// $card-color:                        null !default;
// $card-bg:                           var(--#{$prefix}body-bg) !default;
// $card-img-overlay-padding:          $spacer !default;
// $card-group-margin:                 $grid-gutter-width * .5 !default;
// scss-docs-end card-variables

// Accordion

// scss-docs-start accordion-variables
// $accordion-padding-y:                     1rem !default;
// $accordion-padding-x:                     1.25rem !default;
// $accordion-color:                         var(--#{$prefix}body-color) !default;
// $accordion-bg:                            var(--#{$prefix}body-bg) !default;
// $accordion-border-width:                  var(--#{$prefix}border-width) !default;
// $accordion-border-color:                  var(--#{$prefix}border-color) !default;
// $accordion-border-radius:                 var(--#{$prefix}border-radius) !default;
// $accordion-inner-border-radius:           subtract($accordion-border-radius, $accordion-border-width) !default;

// $accordion-body-padding-y:                $accordion-padding-y !default;
// $accordion-body-padding-x:                $accordion-padding-x !default;

// $accordion-button-padding-y:              $accordion-padding-y !default;
// $accordion-button-padding-x:              $accordion-padding-x !default;
// $accordion-button-color:                  var(--#{$prefix}body-color) !default;
// $accordion-button-bg:                     var(--#{$prefix}accordion-bg) !default;
// $accordion-transition:                    $btn-transition, border-radius .15s ease !default;
// $accordion-button-active-bg:              var(--#{$prefix}primary-bg-subtle) !default;
// $accordion-button-active-color:           var(--#{$prefix}primary-text-emphasis) !default;

// $accordion-button-focus-box-shadow:       $btn-focus-box-shadow !default;

// $accordion-icon-width:                    1.25rem !default;
// $accordion-icon-color:                    $body-color !default;
// $accordion-icon-active-color:             $primary-text-emphasis !default;
// $accordion-icon-transition:               transform .2s ease-in-out !default;
// $accordion-icon-transform:                rotate(-180deg) !default;

// $accordion-button-icon:         url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='#{$accordion-icon-color}' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !default;
// $accordion-button-active-icon:  url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='#{$accordion-icon-active-color}' stroke-linecap='round' stroke-linejoin='round'><path d='M2 5L8 11L14 5'/></svg>") !default;
// scss-docs-end accordion-variables

// Tooltips

// scss-docs-start tooltip-variables
// $tooltip-font-size:                 $font-size-sm !default;
// $tooltip-max-width:                 200px !default;
// $tooltip-color:                     var(--#{$prefix}body-bg) !default;
// $tooltip-bg:                        var(--#{$prefix}emphasis-color) !default;
// $tooltip-border-radius:             var(--#{$prefix}border-radius) !default;
// $tooltip-opacity:                   .9 !default;
// $tooltip-padding-y:                 $spacer * .25 !default;
// $tooltip-padding-x:                 $spacer * .5 !default;
// $tooltip-margin:                    null !default; // TODO: remove this in v6

// $tooltip-arrow-width:               .8rem !default;
// $tooltip-arrow-height:              .4rem !default;
// scss-docs-end tooltip-variables

// Form tooltips must come after regular tooltips
// scss-docs-start tooltip-feedback-variables
// $form-feedback-tooltip-padding-y:     $tooltip-padding-y !default;
// $form-feedback-tooltip-padding-x:     $tooltip-padding-x !default;
// $form-feedback-tooltip-font-size:     $tooltip-font-size !default;
// $form-feedback-tooltip-line-height:   null !default;
// $form-feedback-tooltip-opacity:       $tooltip-opacity !default;
// $form-feedback-tooltip-border-radius: $tooltip-border-radius !default;
// scss-docs-end tooltip-feedback-variables

// Popovers

// scss-docs-start popover-variables
// $popover-font-size:                 $font-size-sm !default;
// $popover-bg:                        var(--#{$prefix}body-bg) !default;
// $popover-max-width:                 276px !default;
// $popover-border-width:              var(--#{$prefix}border-width) !default;
// $popover-border-color:              var(--#{$prefix}border-color-translucent) !default;
// $popover-border-radius:             var(--#{$prefix}border-radius-lg) !default;
// $popover-inner-border-radius:       calc(#{$popover-border-radius} - #{$popover-border-width}) !default; // stylelint-disable-line function-disallowed-list
// $popover-box-shadow:                var(--#{$prefix}box-shadow) !default;

// $popover-header-font-size:          $font-size-base !default;
// $popover-header-bg:                 var(--#{$prefix}secondary-bg) !default;
// $popover-header-color:              $headings-color !default;
// $popover-header-padding-y:          .5rem !default;
// $popover-header-padding-x:          $spacer !default;

// $popover-body-color:                var(--#{$prefix}body-color) !default;
// $popover-body-padding-y:            $spacer !default;
// $popover-body-padding-x:            $spacer !default;

// $popover-arrow-width:               1rem !default;
// $popover-arrow-height:              .5rem !default;
// scss-docs-end popover-variables

// Badges

// scss-docs-start badge-variables
// $badge-font-size:                   .75em !default;
// $badge-font-weight:                 $font-weight-bold !default;
// $badge-color:                       $white !default;
// $badge-padding-y:                   .35em !default;
// $badge-padding-x:                   .65em !default;
// $badge-border-radius:               var(--#{$prefix}border-radius) !default;
// scss-docs-end badge-variables

// Modals

// scss-docs-start modal-variables
// $modal-inner-padding:               $spacer !default;

// $modal-footer-margin-between:       .5rem !default;

// $modal-dialog-margin:               .5rem !default;
// $modal-dialog-margin-y-sm-up:       1.75rem !default;

// $modal-title-line-height:           $line-height-base !default;

// $modal-content-color:               null !default;
// $modal-content-bg:                  var(--#{$prefix}body-bg) !default;
// $modal-content-border-color:        var(--#{$prefix}border-color-translucent) !default;
// $modal-content-border-width:        var(--#{$prefix}border-width) !default;
// $modal-content-border-radius:       var(--#{$prefix}border-radius-lg) !default;
// $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) !default;
// $modal-content-box-shadow-xs:       var(--#{$prefix}box-shadow-sm) !default;
// $modal-content-box-shadow-sm-up:    var(--#{$prefix}box-shadow) !default;

// $modal-backdrop-bg:                 $black !default;
// $modal-backdrop-opacity:            .5 !default;

// $modal-header-border-color:         var(--#{$prefix}border-color) !default;
// $modal-header-border-width:         $modal-content-border-width !default;
// $modal-header-padding-y:            $modal-inner-padding !default;
// $modal-header-padding-x:            $modal-inner-padding !default;
// $modal-header-padding:              $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

// $modal-footer-bg:                   null !default;
// $modal-footer-border-color:         $modal-header-border-color !default;
// $modal-footer-border-width:         $modal-header-border-width !default;

// $modal-sm:                          300px !default;
// $modal-md:                          500px !default;
// $modal-lg:                          800px !default;
// $modal-xl:                          1140px !default;

// $modal-fade-transform:              translate(0, -50px) !default;
// $modal-show-transform:              none !default;
// $modal-transition:                  transform .3s ease-out !default;
// $modal-scale-transform:             scale(1.02) !default;
// scss-docs-end modal-variables

// Alerts
//
// Define alert colors, border radius, and padding.

// scss-docs-start alert-variables
// $alert-padding-y:               $spacer !default;
// $alert-padding-x:               $spacer !default;
// $alert-margin-bottom:           1rem !default;
// $alert-border-radius:           var(--#{$prefix}border-radius) !default;
// $alert-link-font-weight:        $font-weight-bold !default;
// $alert-border-width:            var(--#{$prefix}border-width) !default;
// $alert-dismissible-padding-r:   $alert-padding-x * 3 !default; // 3x covers width of x plus default padding on either side
// scss-docs-end alert-variables

// Progress bars

// scss-docs-start progress-variables
// $progress-height:                   1rem !default;
// $progress-font-size:                $font-size-base * .75 !default;
// $progress-bg:                       var(--#{$prefix}secondary-bg) !default;
// $progress-border-radius:            var(--#{$prefix}border-radius) !default;
// $progress-box-shadow:               var(--#{$prefix}box-shadow-inset) !default;
// $progress-bar-color:                $white !default;
// $progress-bar-bg:                   $primary !default;
// $progress-bar-animation-timing:     1s linear infinite !default;
// $progress-bar-transition:           width .6s ease !default;
// scss-docs-end progress-variables

// List group

// scss-docs-start list-group-variables
// $list-group-color:                  var(--#{$prefix}body-color) !default;
// $list-group-bg:                     var(--#{$prefix}body-bg) !default;
// $list-group-border-color:           var(--#{$prefix}border-color) !default;
// $list-group-border-width:           var(--#{$prefix}border-width) !default;
// $list-group-border-radius:          var(--#{$prefix}border-radius) !default;

// $list-group-item-padding-y:         $spacer * .5 !default;
// $list-group-item-padding-x:         $spacer !default;

// $list-group-hover-bg:               var(--#{$prefix}tertiary-bg) !default;
// $list-group-active-color:           $component-active-color !default;
// $list-group-active-bg:              $component-active-bg !default;
// $list-group-active-border-color:    $list-group-active-bg !default;

// $list-group-disabled-color:         var(--#{$prefix}secondary-color) !default;
// $list-group-disabled-bg:            $list-group-bg !default;

// $list-group-action-color:           var(--#{$prefix}secondary-color) !default;
// $list-group-action-hover-color:     var(--#{$prefix}emphasis-color) !default;

// $list-group-action-active-color:    var(--#{$prefix}body-color) !default;
// $list-group-action-active-bg:       var(--#{$prefix}secondary-bg) !default;
// scss-docs-end list-group-variables

// Image thumbnails

// scss-docs-start thumbnail-variables
// $thumbnail-padding:                 .25rem !default;
// $thumbnail-bg:                      var(--#{$prefix}body-bg) !default;
// $thumbnail-border-width:            var(--#{$prefix}border-width) !default;
// $thumbnail-border-color:            var(--#{$prefix}border-color) !default;
// $thumbnail-border-radius:           var(--#{$prefix}border-radius) !default;
// $thumbnail-box-shadow:              var(--#{$prefix}box-shadow-sm) !default;
// scss-docs-end thumbnail-variables

// Figures

// scss-docs-start figure-variables
// $figure-caption-font-size:          $small-font-size !default;
// $figure-caption-color:              var(--#{$prefix}secondary-color) !default;
// scss-docs-end figure-variables

// Breadcrumbs

// scss-docs-start breadcrumb-variables
// $breadcrumb-font-size:              null !default;
// $breadcrumb-padding-y:              0 !default;
// $breadcrumb-padding-x:              0 !default;
// $breadcrumb-item-padding-x:         .5rem !default;
// $breadcrumb-margin-bottom:          1rem !default;
// $breadcrumb-bg:                     null !default;
// $breadcrumb-divider-color:          var(--#{$prefix}secondary-color) !default;
// $breadcrumb-active-color:           var(--#{$prefix}secondary-color) !default;
// $breadcrumb-divider:                quote("/") !default;
// $breadcrumb-divider-flipped:        $breadcrumb-divider !default;
// $breadcrumb-border-radius:          null !default;
// scss-docs-end breadcrumb-variables

// Spinners

// scss-docs-start spinner-variables
// $spinner-width:           2rem !default;
// $spinner-height:          $spinner-width !default;
// $spinner-vertical-align:  -.125em !default;
// $spinner-border-width:    .25em !default;
// $spinner-animation-speed: .75s !default;

// $spinner-width-sm:        1rem !default;
// $spinner-height-sm:       $spinner-width-sm !default;
// $spinner-border-width-sm: .2em !default;
// scss-docs-end spinner-variables

// Close

// scss-docs-start close-variables
// $btn-close-width:            1em !default;
// $btn-close-height:           $btn-close-width !default;
// $btn-close-padding-x:        .25em !default;
// $btn-close-padding-y:        $btn-close-padding-x !default;
// $btn-close-color:            $black !default;
// $btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>") !default;
// $btn-close-focus-shadow:     $focus-ring-box-shadow !default;
// $btn-close-opacity:          .5 !default;
// $btn-close-hover-opacity:    .75 !default;
// $btn-close-focus-opacity:    1 !default;
// $btn-close-disabled-opacity: .25 !default;
// $btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%) !default;
// scss-docs-end close-variables

// Offcanvas

// scss-docs-start offcanvas-variables
// $offcanvas-padding-y:               $modal-inner-padding !default;
// $offcanvas-padding-x:               $modal-inner-padding !default;
// $offcanvas-horizontal-width:        400px !default;
// $offcanvas-vertical-height:         30vh !default;
// $offcanvas-transition-duration:     .3s !default;
// $offcanvas-border-color:            $modal-content-border-color !default;
// $offcanvas-border-width:            $modal-content-border-width !default;
// $offcanvas-title-line-height:       $modal-title-line-height !default;
// $offcanvas-bg-color:                var(--#{$prefix}body-bg) !default;
// $offcanvas-color:                   var(--#{$prefix}body-color) !default;
// $offcanvas-box-shadow:              $modal-content-box-shadow-xs !default;
// $offcanvas-backdrop-bg:             $modal-backdrop-bg !default;
// $offcanvas-backdrop-opacity:        $modal-backdrop-opacity !default;
// scss-docs-end offcanvas-variables
