.vault-slider-section {
  padding: 5rem 4rem 3rem;
  background-image: url('/assets/frontend/images/vault-slider-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;

  .vault-slider-section-inner {
    @include pulsar-container($oh-container-width-large);
  }

  .swiper-initialized {
    .vault-slide {
      .vault-slide-image {
        img {
          width: 100%;
        }
      }
    }
  }

  .vault-slider {
    position: relative;

    .vault-slide {
      height: unset;
      display: flex;
      flex-direction: column;
      width: auto;
      margin-right: 30px;

      .vault-slide-image {
        img {
          aspect-ratio: 3 / 2;
          object-fit: cover;
        }
      }

      .vault-slide-details {
        @include oh-bg-primary;
        background-color: $oh-black;
        padding: 1.5rem 1rem;
        text-align: center;
        height: 100%;

        .winner-name {
          p {
            @include font-size(18px);
            margin-bottom: 0;
          }
        }

        .winner-location {
          p {
            @include font-size(13px);
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .slider-button-next,
  .slider-button-prev {
    position: absolute;
    width: 64px;
    height: 112px;
    z-index: 2;
    background-color: $oh-green-dark;
    color: $oh-green-bright;
    font-size: 26px;
    top: 50%;
    transform: translateY(-50%);

    &:before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .slider-button-next {
    left: 100%;

    &:before {
      @include fa-pseudo-solid('\f054');
    }
  }

  .slider-button-prev {
    right: 100%;

    &:before {
      @include fa-pseudo-solid('\f053');
    }
  }

  .slider-pagination {
    margin: 3rem 0 0;
    display: flex;
    justify-content: center;

    .swiper-pagination-bullet {
      width: 24px;
      height: 24px;
      background-color: transparent;
      border: 1px solid $oh-green-dark;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: $oh-green-dark;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;

    .slider-button-next,
    .slider-button-prev {
      display: none;
    }
  }

  @include media-breakpoint-down(md) {
    .vault-slider {
      max-width: 360px;
      margin: auto;
    }
  }
}

.vault-prog {
  margin: 3rem 0 0;

  .vault-prog-inner {
    @include pulsar-container($oh-container-width-medium);
  }

  .vault-prog-item {
    padding: 2rem 0;
    border-top: 1px solid $gray-500;

    &:last-of-type {
      border-bottom: 1px solid $gray-500;
    }

    .prog-title {
      a {
        &:not(:hover) {
          color: $primary;
          text-decoration: none;
        }
      }
    }

    .prog-date {
      p {
        @include font-size(13px);
        margin-bottom: 0.5rem;
      }
    }

    .prog-actions {
      display: flex;
      gap: 2rem;
    }
  }
}

.winners-item-title {
  h1 {
    @extend %oh-h2;
  }
}

.winners-item-back {
  margin-bottom: 1rem;
}

.winners-item-image {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 1.5rem;

  img {
    aspect-ratio: 3 / 2;
    object-fit: cover;
  }
}

.vault-prog-pagination {
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  .pagination {
    margin: 0;
  }

  &.vault-prog-pagination--top {
    padding-bottom: 0;
  }
}
