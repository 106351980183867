// Custom global variables
// NOTE: all custom variables should be prefixed with "oh-"
// to distinguish them from Bootstrap and avoid naming collisions
$oh-black: #051705;
$oh-white: #f9faf5;
$oh-green-dark: #17361c;
$oh-green-medium: #1aa34a;
$oh-green-bright: #63ff63;

// ----- container width and padding for pulsar container mixins
$oh-container-width-large: 1140px;
$oh-container-width-medium: 946px;
$oh-container-width-small: 750px;

// bootstrap variable overrides
@import 'bootstrap/variables';

// NOTE: HTML smooth scrolling messes with other functionality
// like anchor tags, form validation, tabbing through links, etc.
$enable-smooth-scroll: false;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';

// ----- plyr vars
:root {
  --plyr-color-main: #{$primary};
}
