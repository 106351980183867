.inner-feature-banner {
  padding: 2.25rem 0;
  background-color: $oh-black;
  color: $white;

  .inner-feature-banner-inner {
    @include pulsar-container($oh-container-width-large);
    display: flex;
    justify-content: center;
    text-align: center;

    h1 {
      position: relative;
      margin: 0;

      &::before,
      &::after {
        position: absolute;
        @include fa-pseudo-solid('\f005');
        top: 50%;
        transform: translateY(-50%);
        font-size: 12px;
      }

      &:before {
        right: calc(100% + 0.5rem);
      }

      &:after {
        left: calc(100% + 0.5rem);
      }
    }
  }
}

.content-wrap {
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 4rem;

  &.width--large {
    @include pulsar-container($oh-container-width-large);
  }

  &.width--medium {
    @include pulsar-container($oh-container-width-medium);
  }

  &.width--small {
    @include pulsar-container($oh-container-width-small);
  }
}
