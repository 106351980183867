.terminals {
  .terminal {
    margin-bottom: 5rem;
  }

  .terminal-title {
    text-align: center;
    margin-bottom: 2rem;
  }

  .terminal-details {
    display: grid;
    gap: 2rem;
    margin-bottom: 4rem;
  }

  .terminal-image {
    display: flex;
    justify-content: center;
  }

  .terminal-description {
    text-align: center;
    max-width: 650px;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    .terminal-details {
      display: flex;
      justify-content: center;
      align-items: center;

      &.terminal-details--image-and-description {
        .terminal-description {
          text-align: left;
        }
      }
    }

    .terminal-image {
      width: 100%;
      max-width: 380px;
    }
  }
}

.terminal-games {
  @include oh-tiles-grid(3);
}

.terminal-game-card {
  display: flex;
  flex-direction: column;
  border: 1px solid $primary;
  width: 100%;
  max-width: 360px;
  height: 100%;
  margin: auto;

  .terminal-game-image {
    position: relative;
    padding: 1rem;

    > img {
      aspect-ratio: 3/2;
      object-fit: contain;
      width: 100%;
    }
  }

  .terminal-game-title {
    h3 {
      @extend %oh-h1;
      @include font-size(25px);
      color: $primary;
    }
  }

  .terminal-game-details {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.25rem;
    background-color: $oh-white;
  }

  .terminal-game-copy {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    margin-bottom: 1.5rem;
  }

  .terminal-game-more {
    margin-top: auto;

    button {
      width: 100%;
    }
  }
}

.terminal-game-fancybox {
  width: 100%;
  max-width: $oh-container-width-medium;

  .terminal-title {
    h2 {
      @extend %oh-h5;
    }
  }

  .terminal-game-title {
    margin-bottom: 1rem;

    h3 {
      @extend %oh-h1;
      color: $primary;
    }
  }

  .terminal-game-image {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 12rem;
    margin-bottom: 3rem;
  }
}
